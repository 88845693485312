import { useState, useEffect } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GoPencil } from "react-icons/go";
import { FaTrashAlt } from "react-icons/fa";
import { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Helmet from "react-helmet";
import moment from "moment";

import {
  deletePost,
  deleteEvent,
  deleteItem,
  deleteJob,
  deleteProject,
  deleteEducation,
} from "../graphql/mutations";
import * as queries from "../graphql/queries";
import useIsMounted from "../lib/useIsMounted";

export default withAuthenticator(Create);

const TABLE_FIELDS = {
  post: [
    { displayName: "Title", field: "title" },
    { displayName: "Category", field: "category" },
    { displayName: "Created At", field: "createdAt" },
  ],
  event: [
    { displayName: "Name", field: "name" },
    { displayName: "Start", field: "start" },
    { displayName: "End", field: "End" },
  ],
  item: [
    { displayName: "Name", field: "name" },
    { displayName: "Category", field: "category" },
    { displayName: "Created At", field: "createdAt" },
  ],
  job: [
    { displayName: "Role", field: "role" },
    { displayName: "Company", field: "company" },
    { displayName: "Location", field: "location" },
  ],
  project: [
    { displayName: "Name", field: "name" },
    { displayName: "Status", field: "status" },
  ],
  education: [
    { displayName: "Organization", field: "organization" },
    { displayName: "Degree", field: "degree" },
  ],
};

function Create() {
  const [items, setItems] = useState([]);
  const [itemType, setItemType] = useState("post");

  const isMounted = useIsMounted();
  const navigate = useNavigate();

  async function deleteEntity(type, id) {
    if (!type || !id) return;

    let mutation = null;
    if (itemType === "post") {
      mutation = deletePost;
    } else if (itemType === "event") {
      mutation = deleteEvent;
    } else if (itemType === "item") {
      mutation = deleteItem;
    } else if (itemType === "job") {
      mutation = deleteJob;
    } else if (itemType === "project") {
      mutation = deleteProject;
    } else if (itemType === "education") {
      mutation = deleteEducation;
    }

    if (mutation) {
      await API.graphql(graphqlOperation(mutation, { input: { id } }));
    }
  }

  useEffect(() => {
    async function fetchData() {
      let query = null;

      if (itemType === "post") {
        query = "listPosts";
      } else if (itemType === "event") {
        query = "listEvents";
      } else if (itemType === "item") {
        query = "listItems";
      } else if (itemType === "job") {
        query = "listJobs";
      } else if (itemType === "project") {
        query = "listProjects";
      } else if (itemType === "education") {
        query = "listEducations";
      }

      if (query) {
        const data = await API.graphql({ query: queries[query] });
        const fetchedItems = data.data[query].items;
        if (isMounted.current) setItems(fetchedItems);
      }
    }
    fetchData();
  }, [isMounted, itemType]);

  let sortedItems = items.sort(function (a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (b.createdAt < a.createdAt) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Helmet>
        <title>Create</title>
      </Helmet>
      <div className="mb-5">
        <Dropdown size="lg" className="d-inline">
          <Dropdown.Toggle
            variant="light"
            size="lg"
            className="bg-transparent p-0 text-capitalize border-0"
          >
            {itemType}
            {["post", "event", "item", "job", "project"].includes(itemType)
              ? "s"
              : ""}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {["post", "event", "item", "job", "project", "education"].map(
              (type) => (
                <Dropdown.Item
                  key={type}
                  onClick={() => setItemType(type)}
                  className="text-capitalize"
                >
                  {type}
                  {["post", "event", "item", "job", "project"].includes(type)
                    ? "s"
                    : ""}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Table hover className="cursor-pointer">
        <thead>
          <tr>
            {TABLE_FIELDS[itemType].map(({ displayName, field }) => (
              <th key={`header-${field}`} className="px-0">
                {displayName}
              </th>
            ))}
            <th className="px-0"></th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.length > 0 ? (
            sortedItems.map((item, i) => {
              return (
                <tr key={i} onClick={() => navigate(`/${itemType}/${item.id}`)}>
                  {TABLE_FIELDS[itemType].map(({ field }) => (
                    <td key={field} className="px-0">
                      {field === "createdAt"
                        ? moment(item[field]).format("dddd, MMM D, YYYY")
                        : item[field]}
                    </td>
                  ))}
                  <td className="px-0">
                    <span
                      className="me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/${itemType}/${item.id}/edit`);
                      }}
                    >
                      <GoPencil
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          color: "#6c757d",
                        }}
                      />
                    </span>
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const shouldDelete = window.confirm("Delete the item?");
                        if (shouldDelete) {
                          deleteEntity(itemType, item.id);
                        }
                      }}
                    >
                      <FaTrashAlt
                        className="ml-2"
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          color: "#dc3545",
                        }}
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No items</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
